<svelte:options tag="fds-input-text"></svelte:options>
<!--
@component
Here's some documentation for this component.
It will show up on hover.

- You can use markdown here.
- You can also use code blocks here.
- Usage:
  ```tsx
  <main name="Arethra">
  ```
-->
<script>
    /**
     * flying dog one line text field component coming with several pre-defined themes, colors and can be customized.
     */
    import {beforeUpdate, createEventDispatcher, onMount} from 'svelte'
    import {themes} from "./themes.js"
    import {get_current_component} from 'svelte/internal';
    import fdsHelper from "@fds-components/fds-helper"
    import {name, version as componentversion} from '../package.json';

    const path = fdsHelper.get_href()


    /**
    * @typedef {string} cssColorString
    * @typedef {string} iconString
    */

    /**
     * Set theme
     * @type  {"" | "classic" | "rounded" | "underline" | "whiteShadow"}
     */
    export let theme=""
    /**
     * Sets the status which defines a different border color
     * @type  {"default" | "error" | "warning" | "success"}
     */
    export let status="default"
    /**
     * Sets the size
     * @type  {"default" | "lg" | "sm" }
     */
    export let size="default"
    /**
     * HTML input type: text and password only are recommended - depending on browser support maybe more in future
     * @type  {"text" | "email" | "password"  | "number"}
     */
    export let type="text"
    /**
     * The text field width in CSS value (e.g. 200px)
     * @type {string}
     */
    export let width="100%"
    /**
     * Sets the value
     * @type {string}
     */
    export let value=""
    /**
     *  HTML input placeholder
     * @type {string}
     */
    export let placeholder=""
    /**
     * If true the value of input will be shown without width limitation
     * @type {false | true }
     */
    export let readonly=false
    /**
     * Set input to grey disabled state and deactivates editing
     * @type {false | true }
     */
    export let disabled=false
    /**
     * Shows an icon at front of input
     * @type {iconString}
     */
    export let icon_prepend=""
    /**
     * Shows an icon at end of input
     * @type {iconString}
     * */
    export let icon_append=""
    /**
     * method fired on icon click
     * @type {function}
     */
    export let iconclick = null
    /**
     * CSS value of icon color
     * @type {cssColorString}
     */
    export let iconcolor = ""

        let host = get_current_component()

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version"){
            return new Promise(resolve => {
                    resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type,name);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    /**
     * Will be fired if an icon has clicked
     * @event iconClick
     */
    let iconClickHandler = (e)=>{
        dispatch("iconClick", e);
        if(iconclick && iconclick  === 'function'){iconclick(e);}
    }

    let svelteDispatch = createEventDispatcher();
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail)
        host.dispatchEvent && host.dispatchEvent(new CustomEvent(name, { detail }))
    }
    let error=""
    let ref=null

    beforeUpdate(()=> {
        setProps();
    })

    onMount(()=> {
        setProps()
        if (ref) {
            ref.type = type
        }
    })

    /**
     * get Theme object
     * @param {string} status
     * @param {string} size
     * @param {string} disabled
     * @param {string} theme
     * @returns {object}
     */
    export function getTheme(status,size,disabled,theme) {
        /* custom themes */
        if (window["fds-ui-config"] && window["fds-ui-config"].inputThemes) {
            let customThemes = window["fds-ui-config"].inputThemes
            for (let name in customThemes) {
                let obj = customThemes[name]
                let baseTheme = themes[obj.use]
                let newTheme = {...baseTheme}
                for (let key in obj) {
                    if (key !== "className") newTheme[key] = obj[key]
                }
                if (obj.use) themes[name]=newTheme
            }
        }

        if (!theme && window["fds-ui-config"]) theme=window["fds-ui-config"].inputTheme
        if (!theme) theme="defaultTheme"
        let t= themes[theme]
        if (!t) t= themes["defaultTheme"]
        t=JSON.parse(JSON.stringify(t))
        t.theme=theme
        t.status=status
        if (!status) t.status="default"
        let statusColor=t.statusColor[t.status]

        if (statusColor && statusColor.border) t.borderColor=statusColor.border
        if (statusColor && statusColor.icon) t.iconColor=statusColor.icon
        if (statusColor && statusColor.background) t.backgroundColor=statusColor.background
        t.size=size
        if (!size) t.size="default"

        if (!t["size_"+t.size]) {
            error="Unknown size: "+t.size
            t.height="30px"
            t.fontSize="10px"
        } else {
            t.height=t["size_"+t.size].height
            t.fontSize=t["size_"+t.size].fontSize
            t.iconHeight=t["size_"+t.size].iconHeight
        }
        if (disabled===true){
            t.backgroundColor=t.disabledBackgroundColor;
        }
        if (!t.backgroundColor) t.backgroundColor="transparent"
        t.overflow="none"
        if (theme==="underline") t.overflow="hidden"
        return t
    }
    $:{
        if(readonly=='false') readonly = false;   readonly = !!readonly;
        if(disabled=='false') disabled = false;   disabled = !!disabled;
    }
    $: setProps()

    let prop={}
    function setProps() {
        prop=getTheme(status,size,disabled,theme)

        if (width) prop.width=width

        width=prop.width
        if (!width) width="100%"

        if (error) console.log(error)
        prop.paddingLeft="5px";
        if (icon_prepend) {
            prop.paddingLeft=prop.iconHeight+15+"px";
        }
        prop.paddingRight="5px";
        if (icon_append) {
            prop.paddingRight=prop.iconHeight+15+"px";
        }
        if (iconclick) {
            prop.iconCursor="pointer";
        }
        if(iconcolor){
            prop.iconColor=iconcolor
        }
    }
    /**
     * Snowball event.
     *
     * @event snowball
     * @type {object}
     * @property {boolean} isPacked - Indicates whether the snowball is tightly packed.
     */

    /**
     * Will be fired if value has been changed
     *
     * @event change
     */
    function handleChange(e) {
        dispatch("change", {
            value: value //passing argument
        })
    }
</script>
<span class="container" style="--overflow:{prop.overflow}">

{#if !readonly}
    <input bind:this={ref} bind:value={value}  on:change={e => handleChange(e)}  placeholder="{placeholder}"  class="{prop.className}"
           style="--round:{prop.round};--shadow: {prop.shadow};
           --width: {width};--height:{prop.height};--font-size:{prop.fontSize};
           --border-color:{prop.borderColor};--background-color:{prop.backgroundColor};--focus-color:{prop.focusColor};--color:{prop.color};
           --padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight}"
           disabled="{disabled}"
           id="input" >
    {#if prop.theme==="underline"}<span class="underline-animation" style="--focus-color:{prop.focusColor}" ></span>{/if}

{:else}
    <span class="readonly" style="--width: {width};--height:{prop.height};--font-size:{prop.fontSize};--padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight}">
            {#if !value}-{:else}{value}{/if}
    </span>
{/if}
    {#if icon_prepend}<fds-icon on:click={iconClickHandler} name="{icon_prepend}" height="{prop.iconHeight}" class="iconPrepend" style="--icon-cursor:{prop.iconCursor};--color:{prop.iconColor}"></fds-icon>{/if}

    {#if icon_append}<fds-icon on:click={iconClickHandler} name="{icon_append}" height="{prop.iconHeight}" class="iconAppend" style="--icon-cursor:{prop.iconCursor};--color:{prop.iconColor}"></fds-icon>{/if}
</span>
<style>
    * {
        box-sizing: border-box;
    }
.container {
    position: relative;
    height: var(--height);
    display:block;
    overflow:var(--overflow);

}
.iconPrepend {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor:  var(--icon-cursor,'auto');
}
.iconAppend {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
         cursor:  var(--icon-cursor,'auto');
}
.input {
    color: var(--color);
    background: var(--background-color);
    font-size: var(--font-size);
    height: var(--height);
    width: var(--width);
    padding:0;
    border: 1px solid;
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);;
    position:relative;
    display:inline-block;
    border-color: var(--border-color);
    border-radius: var(--round);
    box-shadow: var(--shadow);
    outline: none !important;
    font-family: inherit;
}
.focusDefault:focus {
    border-color: var(--focus-color);
}
.readonly {
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);;
    font-size: var(--font-size);
    height: var(--height);
    width: var(--width);
}
.underline {
    border: 0;
    border-bottom: 2px solid var(--border-color);
}

.underline-animation{
    transition: all 0.5s;
    display: inline-block;
    bottom: 0;
    left: -100%;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color:  var(--focus-color)
}
.container >  .input:focus + .underline-animation{
    left: 0;
}
</style>
