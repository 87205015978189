

const classic= {
    shadow:"",
    round: 0,
    className: "input focusDefault",
    statusColor:     {
        "default": {
            border: "#A0A6A8",
            icon: "#A0A6A8"
        },
        "success": {
            border:"#529300",
            icon:"#529300"
        },
        "warning": {
            border: "#ff6a00",
            icon: "#ff6a00"
        },
        "error": {
            border: "#e60000",
            icon: "#e60000"
        }
    },
    disabledBackgroundColor: "#e5e3e3",
    selectColor: "#c2c2c2",
    focusColor: "#52afbe",
    color: "black",
    size_sm: {
        height: "26px",
        fontSize: "14px",
        iconHeight: 15
    },
    size_default: {
        height: "36px",
        fontSize: "16px",
        iconHeight: 18
    },
    size_lg: {
        height: "48px",
        fontSize: "20px",
        iconHeight: 22
    }
}

const rounded={...classic}
rounded.round="5px"
const fullRounded={...classic}
fullRounded.round="30px"

const underline={...classic}
underline.className="input underline"
underline.statusColor= {
    "default": {
        border: "#dadada",
            icon: "#A0A6A8"
    },
    "success": {
        border:"#529300",
            icon:"#529300"
    },
    "warning": {
        border: "#ff6a00",
            icon: "#ff6a00"
    },
    "error": {
        border: "#e60000",
            icon: "#e60000"
    }
};



const whiteShadow={...classic}
whiteShadow.shadow= "5px 5px 6px -3px rgba(0,0,0,0.17)"
whiteShadow.backgroundColor="white"
whiteShadow.statusColor=     {
    "default": {
        border: "transparent",
        icon: "#A0A6A8"
    },
    "success": {
        border:"#529300",
        icon:"#529300"
    },
    "warning": {
        border: "#ff6a00",
        icon: "#ff6a00"
    },
    "error": {
        border: "#e60000",
        icon: "#e60000"
    }
}
export const themes={defaultTheme:classic,
    classic: classic,
    rounded: rounded,
    fullRounded: fullRounded,
    underline: underline,
    whiteShadow: whiteShadow
                }
